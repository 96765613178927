<template>
  <div class="bill-check-detail-container">
    <my-nav-bar
      title="账单明细"
      left-text="返回"
      left-arrow
    />
    <div v-if="bill" :class="bill.status === 0 ? 'app-main-fixed-container' : 'app-main-container'">
      <div v-if="bill" class="bill-check-detail">
        <van-cell-group title="账单明细">
          <van-cell value-class="amount" title="订单总额" :value="'￥' + bill.amount" is-link center @click="toOrderView" />
          <van-cell v-if="depositAmount < 0" value-class="amount" title="扣减定金" :value="'￥' + depositAmount" />
          <van-cell v-if="bill.red_packet_amount < 0" value-class="amount" title="扣减红包" :value="'￥' + bill.red_packet_amount" />
          <van-cell v-if="bill.coupon_amount < 0" value-class="amount" title="扣减优惠券" :value="'￥' + bill.coupon_amount" />
          <van-cell v-if="bill.return_amount < 0" value-class="amount" title="扣减退货" :value="'￥' + bill.return_amount" is-link center @click="toAfterView" />
          <van-cell v-if="bill.unpaid_amount < 0" value-class="amount" title="扣减签单" :value="'￥' + bill.unpaid_amount" is-link center @click="toUnpaidOrderView" />
          <van-cell v-if="bill.decimal_amount < 0" value-class="amount" title="扣减尾数" :value="'￥' + bill.decimal_amount" />
          <van-cell v-if="bill.expense_amount < 0" value-class="amount" title="报销(额外计算)" :value="'￥' + bill.expense_amount" />
          <van-cell value-class="amount" title="应收" :value="'￥' + bill.real_amount" />
        </van-cell-group>

        <van-cell-group v-if="bill.unpaid_amount < 0" title="签单明细">
          <van-cell v-if="bill.unpaid_return_amount < 0" title="签单退货金额" :value="'￥' + bill.unpaid_return_amount" is-link center value-class="amount" @click="toAfterView" />
          <van-cell title="本次签单金额" :value="'￥' + bill.real_unpaid_amount" value-class="amount" />
        </van-cell-group>

        <van-cell-group v-if="bill.wxpay_amount > 0 || bill.cash_amount != 0 || bill.trans_amount > 0" title="支付方式">
          <van-cell v-if="bill.wxpay_amount > 0" value-class="amount" title="线上支付" :value="'￥' + bill.wxpay_amount" />
          <van-cell v-if="bill.cash_amount != 0" value-class="amount" title="现金支付" :value="'￥' + bill.cash_amount" />
          <van-cell v-if="bill.trans_amount > 0" value-class="amount" title="转账支付" :value="'￥' + bill.trans_amount" />
        </van-cell-group>

        <van-cell-group v-if="bill.wxpay_amount > 0" title="支付实收">
          <van-cell
            :value-class="bill.payment_online_amount == bill.wxpay_amount ? 'finished-amount' : 'amount'"
            title="线上支付实收"
            is-link
            center
            :value="`￥${bill.payment_online_amount}`"
            @click="toPaymentOnlineView"
          />
        </van-cell-group>

        <van-cell-group v-if="bill.deposit_wxpay_amount < 0 || bill.deposit_alipay_amount < 0 || bill.deposit_cash_amount < 0" title="预付定金">
          <van-cell v-if="bill.deposit_wxpay_amount < 0" value-class="amount" title="微信定金" center :value="`￥${-bill.deposit_wxpay_amount}`" />
          <van-cell v-if="bill.deposit_alipay_amount < 0" value-class="amount" title="支付宝定金" center :value="`￥${-bill.deposit_alipay_amount}`" />
          <van-cell v-if="bill.deposit_cash_amount < 0" value-class="amount" title="现金定金" center :value="`￥${-bill.deposit_cash_amount}`" />
        </van-cell-group>

        <!-- <van-cell-group v-if="bill.status === 0" title="账单管理">
          <van-cell title="修改账单日期" is-link center :value="bill.bill_date" @click="showDatePicker = true" />
        </van-cell-group> -->
      </div>

      <!-- <div v-if="bill.status === 0" class="fixed-bottom-btn">
        <van-button block square @click="handleCheck">确认完成</van-button>
      </div> -->
    </div>
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker v-model="date" type="date" title="修改账单日期" @cancel="showDatePicker = false" @confirm="handleDateConfirm" />
    </van-popup>
  </div>
</template>

<script>
import billCheckApi, { modifyBillDate, check } from '@/api/bill_check'
import myNavBar from '@/components/my-nav-bar'
import { toDateString } from '@/utils'
export default {
  name: 'BillCheckDetail',
  components: { myNavBar },
  data() {
    return {
      bill: null,
      showDatePicker: false,
      date: new Date()
    }
  },
  computed: {
    depositAmount() {
      return (Number(this.bill.deposit_wxpay_amount) +
          Number(this.bill.deposit_alipay_amount) +
          Number(this.bill.deposit_cash_amount)).toFixed(2)
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.beginLoad()
      const params = {
        bill_id: this.$route.query.bill_id
      }
      billCheckApi.detail(params).then(res => {
        this.endLoad()
        this.bill = res.data
      })
    },
    toUnpaidOrderView() {
      this.$router.push({
        path: 'bill-check-unpaid-order',
        query: {
          bill_id: this.$route.query.bill_id
        }
      })
    },
    toOrderView() {
      this.$router.push({
        path: 'bill-check-order',
        query: {
          bill_id: this.$route.query.bill_id
        }
      })
    },
    toAfterView() {
      this.$router.push({
        path: 'bill-check-after-order',
        query: {
          bill_id: this.$route.query.bill_id
        }
      })
    },
    toPaymentOnlineView() {
      this.$router.push({
        path: '/bill-check-payment-online',
        query: {
          bill_id: this.bill.id,
          bill_type: this.bill.type
        }
      })
    },
    handleDateConfirm() {
      const date = toDateString(this.date)
      const data = { bill_id: this.bill.id, bill_date: date }
      this.beginLoad()
      modifyBillDate(data).then(res => {
        this.endLoad()
        this.showDatePicker = false
        this.bill.bill_date = date
      })
    },
    handleCheck() {
      this.beginLoad()
      const data = {
        bill_id: this.bill.id
      }
      check(data).then(res => {
        this.success(res.msg)
        this.$eventBus.$emit('bill-finished')
        this.$router.back()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .bill-check-detail-container{
    .btn-wrap{
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      background-color: white;
    }
  }
  .bill-check-detail {
    padding: 0 15px 15px 15px;
  }
  .amount {
    color: #ee0a24;
  }
  .finished-amount {
    color: #07c160;
  }
  .show-footer-btn {
    padding-bottom: 65px;
  }

</style>
